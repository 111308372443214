import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Contact from "../components/Contact/Contact"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"

const contact = () => {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
        <BannerModule
          title="Contact"
          subTitle="Neem contact met met op en ik kom zo spoedig mogelijk bij u terug."
        />
        <Contact />
      </Layout>
    </>
  )
}

export default contact
