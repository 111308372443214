import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles className="section">
       <form name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <input placeholder="Uw naam..." type="text" name="name" />
        <input placeholder="Uw emailadres..." type="email" name="email" />
        <input placeholder="Uw telefoonnummer..." type="text" name="phone" />
        <textarea
          placeholder="Uw bericht..."
          name="message"
          rows="5"
        ></textarea>
       <Button type="submit" text="Verstuur vraag" />
      </form>
    </ContactStyles>
  )
}

export default Contact
